
.multiselect {
  @apply border border-slate-200 rounded-lg bg-white text-sm;
  &__active {
    @apply text-sm;
  }
  &__select {
    @apply bg-white;
}
  &__tags {
    @apply  bg-white border border-slate-200;
  }
  &__option:hover {
    @apply text-sm bg-orange-400 text-black;
  }
  &__option--highlight {
    @apply text-sm bg-orange-400 text-black;
  }
  &__tag {
    @apply text-sm bg-orange-400 text-black;
  }
  &__option--selected.multiselect__option--highlight,
  &__option--selected.multiselect__option--highlight:after {
    @apply text-red-400 bg-red-100;
  }

}
