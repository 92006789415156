@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

body {
  -webkit-tap-highlight-color: transparent;
}

.v-popper--theme-tooltip a {
  @apply text-orange-300 underline;
}
